@use 'sass:math';
@import "src/assets/scss/core";
.button-c {
    --height: #{rem(32)};
    --text-color: #{sc(hero)};
    height: var(--height);
    min-width: var(--height);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 rem(15);
    background: c(hero);
    color: var(--text-color);
    border: rem(1) solid c(hero);
    transition: 400ms background-color, 400ms border-color, 400ms color, 400ms transform, 400ms box-shadow;
    font: 700 f(14, 22) $f;
    border-radius: rem(4);
    overflow: hidden;
    cursor: pointer;
    position: relative;
    @include hover {
        color: var(--text-color);
        background: c(hero-hover);
    }
    &.s-small {
        padding: 0 rem(8);
        font-size: rem(12);
        font-weight: 400;
        --height: #{rem(22)};
    }
    &.s-large {
        font-size: rem(16);
        border-radius: rem(8);
        --height: #{rem(40)};
    }
    &.s-extra-large {
        font-size: rem(16);
        --height: #{rem(44)};
        border-radius: rem(8);
    }
    .text-transition {
        min-height: rem(22);
        > div {
            &:only-child {
                position: static !important;
            }
        }
    }
    &.a-outline {
        --text-color: #{c(tertiary)};
        border-color: c(neutral-5);
        background: c(b);
        @include hover {
            border-color: c(tertiary);
        }
    }
    &.a-outline-dashed {
        --text-color: #{c(tertiary)};
        border-color: c(neutral-5);
        background: c(b);
        border-style: dashed;
        @include hover {
            border-color: c(tertiary);
        }
    }
    &.a-outline-hero {
        --text-color: #{c(hero)};
        border-color: c(hero);
        background: c(b);
        @include hover {
            color: c(hero-hover);
            border-color: c(hero-hover);
        }
    }
    &.a-outline-white {
        --text-color: #{sc(hero)};
        border-color: sc(hero);
        background: sc(hero, 0);
        @include hover {
            color: c(hero);
            background: sc(hero, 1);
        }
    }
    &.a-clear {
        color: c(tertiary);
        border-color: c(tertiary, 0);
        background-color: c(tertiary, 0);
        border-radius: rem(14);
        &.disabled {
            opacity: 0.4;
            background: none;
        }
        @include hover {
            background-color: c(tertiary, 0.04);
        }
    }
    &.a-unset {
        border: 0;
        background: none;
        color: c(tertiary);
        min-width: inherit;
        height: auto;
        @include hover {
            color: c(hero);
        }
    }
    &.disabled {
        --text-color: #{sc(b, 0.25)};
        border-color: c(neutral-5, 0);
        pointer-events: none;
        &:not(.a-outline):not(.a-clear) {
            background: c(neutral-3);
        }
    }
    &:active {
        transform: scale(0.95);
    }
    &.loading {
        color: transparent !important;
        pointer-events: none;
    }
    &.f-full {
        width: 100%;
    }
    .ant-form-item-control-input-content & {
        font-weight: 400;
    }
    &.np {
        padding: 0;
    }
}
.inline-loader-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 400ms transform, 400ms opacity;
    &:not(.loading) {
        transform: scale(0.6);
        opacity: 0;
        .inline-loader {
            &, &:after, &:before {
                animation-play-state: paused;
            }
        }
    }
}
.inline-loader {
    $gap: 4px;
    $size: 8px;
    $unique-name: unique-id();
    $duration: 0.6s;
    width: $size;
    height: $size;
    border-radius: 50%;
    animation-delay: math.div($duration, 5) * 2;
    position: absolute;
    top: 50%;
    margin: -($size + math.div($size, 2)) auto 0;
    animation: #{'inline-loader-'}#{$unique-name} $duration ease alternate infinite;
    &:after, &:before {
        content: '';
        position: absolute;
        width: $size;
        height: $size;
        border-radius: 50%;
        animation: #{'inline-loader-'}#{$unique-name} $duration ease alternate infinite;
    }
    &:before {
        left: -($size + $gap);
        animation-delay: math.div($duration, 5) * 1;
    }
    &:after {
        right: -($size + $gap);
        animation-delay: math.div($duration, 5) * 3;
    }
    @keyframes #{'inline-loader-'}#{$unique-name} {
        0% {
            box-shadow: 0 $size 0 (-$size) var(--text-color);
        }
        100% {
            box-shadow: 0 $size 0 var(--text-color);
        }
    }
}
.button-c-icon {
    display: flex;
    &:not(.nm) {
        margin: 0 rem(8) 0 0;
    }
    .s-medium & {
        &:not(.nm) {
            margin: 0 rem(8) 0 0;
        }
        .anticon {
            color: sc(b, 0.25);
        }
    }
}
.text-transition {
    position: relative;
}
