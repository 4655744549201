@import "src/assets/scss/core";
.logo-holder {
    display: flex;
    overflow: hidden;
    transition: 400ms transform;
    &:active {
        transform: scale(0.95);
    }
    &.fixed {
        position: fixed;
        z-index: 100;
    }
    svg {
        width: 100%;
        height: 100%;
    }
    rect {
        fill: c(hero);
        transition: 400ms fill;
    }
    path {
        fill: sc(hero);
    }
    @include hover {
        rect {
            fill: c(hero-hover);
        }
    }
}
