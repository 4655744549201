html {
    @media screen and (min-width: 731px) {
        @media screen and (max-width: 920px) {
            font-size: 9px;
        }
        &[flp="shared"] {
            @media screen and (max-width: 820px) {
                font-size: 10px;
            }
        }
        &:not([flp="shared"]) {
            @media screen and (max-width: 820px) {
                font-size: 8px;
            }
        }
    }
}
.margin-auto {
    margin: auto;
}
.sk-spinner {
    color: c(hero);
    &.sk-fade-in {
        animation-duration: 250ms;
    }
}
.link-hover {
    background: linear-gradient(to right, transparent, transparent), linear-gradient(to right, currentColor, currentColor);
    background-size: 100% rem(1), 0 rem(1);
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: 400ms color, 400ms background, 400ms border-color, 400ms box-shadow, 400ms background-size;
    @include hover {
        background-size: 0 rem(1), 100% rem(1);
    }
}
.link-effect {
    position: relative;
    display: inline-block;
    transform: translate3d(0, 0, 0);
    &:after, &:before {
        content: '';
        position: absolute;
        bottom: rem(3);
        left: 0;
        width: 100%;
        height: rem(1);
        background: currentColor;
        transform-origin: right;
        transform: scaleX(1) translateZ(0);
        transition: transform .6s cubic-bezier(.165, .84, .44, 1) .3s;
    }
    &:after {
        transform-origin: left;
    }
    &:not(:hover):after, &:hover:before {
        transform: scaleX(0) translateZ(0);
        transition: transform .6s cubic-bezier(.165, .84, .44, 1);
    }
    &.hover-active {
        &:not(:hover):not(.active) {
            &:before {
                transform: scaleX(0) translateZ(0);
            }
        }
    }
}
