@import "src/assets/scss/core";
.no-data-holder {
    width: 100%;
    min-height: 100%;
    display: flex;
    padding: rem(24);
}
.no-data-content, .no-data-headings {
    gap: rem(16);
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.no-data-icon {
    color: sc(b, 0.3);
}
.no-data-headings {
    gap: rem(8);
}
.no-data-title {
    font: 700 f(18) $f;
}
.no-data-sub-title {
    font: f(15) $f;
    color: sc(b, 0.5);
}
.no-data-description {
    font: f(13) $f;
    color: sc(b, 0.3);
}
