@import "src/assets/scss/core";
.loader-holder {
    width: 100%;
    min-height: 100%;
    display: flex;
    transform: scale(0);
    transition: 340ms transform;
    pointer-events: none;
    &.active {
        transform: scale(1);
    }
    &.main {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000000;
        &.active {
            transition-delay: 200ms;
        }
    }
}
.lottie-loader {
    margin: auto;
}
