@import "src/assets/scss/core";
.icon-c-holder {
    width: rem(24);
    height: rem(24);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    svg, img {
        width: 100%;
        height: 100%;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.5;
        object-fit: contain;
    }
    svg + img {
        display: none;
    }
    &.t-solid {
        svg {
            *:not([fill]):not([stroke]) {
                fill: currentColor;
            }
        }
    }
    &.n-loader-2 {
        svg, img {
            animation: rotate 300ms infinite linear;
        }
    }
}
