@import 'src/assets/scss/core';
.animated-holder {
    opacity: 0;
    &:not(.fit) {
        width: 100%;
    }
    &.animate {
        animation: fadeInUp 0.7s forwards;
        &.a-fadeIn {
            animation-name: fadeIn;
        }
        &.a-bounceIn {
            animation: bounceIn 1s forwards;
        }
    }
}
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, calc(var(--animate, #{rem(150)}) * var(--directtion, 1)), 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
