$f: 'Figtree', -apple-system, BlinkMacSystemFont, sans-serif;
$fs: 'Osulent Signature Regular', -apple-system, BlinkMacSystemFont, sans-serif;
$fw: 'Sequel100Wide-95', -apple-system, BlinkMacSystemFont, sans-serif;

$mobile: 730px;
$lineHeight: 16;

$setColorDark: desaturate(#0b0c25, 50);

$c: (
    b: #ffffff,
    hero: #54AD95,
    info: #0099CC,
    danger: #ff5d55,
    success: #00c851,
    warning: #ff8800,
    divider: #eaeef2,
    tertiary: #3A3E44,
    neutral-5: #D9D9D9,
    neutral-3: #f3f3f5,
    f: #15171e,
);

$setColorLightList: (
    #54AD95,
    #ff5d55,
);

@import "clyne-core/index";
